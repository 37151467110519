



























































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Order from '@/types/order'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class OrderShowDetailsCard extends Vue {
  settings = {
    routeNames: { list: 'orders', show: 'orderShow', edit: 'orderEdit' },
    routeParamKey: 'orderId',
    functionNames: { setCurrent: 'setCurrentOrder', save: 'saveOrder' },
    getterNames: { current: 'currentOrder', loading: 'loadingOrder' }
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  openOrderPdf () : void {
    if (this.currentItem.pdf) {
      window.open(this.currentItem.pdf.url, '_blank')
    }
  }

  regenerateOrderPdf () : void {
    axios.post(process.env.VUE_APP_API_URL + '/orders/' + this.currentItem.id + '/pdf/regenerate').then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
      notification.success({ message: 'PDF opnieuw gegenereerd.', description: 'De PDF is succesvol opnieuw gegenereerd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'PDF NIET opnieuw gegenereerd.', description: 'De PDF is NIET opnieuw gegenereerd.', duration: 0 })
    })
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Order {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}

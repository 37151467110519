















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingEditFinancialTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  loading = false

  sentInvoicesColumns:Column[] = [
    { title: 'Factuurnummer', key: 'reference', dataIndex: 'reference' },
    { title: 'Totaal', key: 'totals.total', scopedSlots: { customRender: 'total' }, width: 140 },
    { title: 'Aangemaakt op', key: 'generated_at', scopedSlots: { customRender: 'generated_at' }, width: 200 },
    { title: 'Actie\'s', key: 'actions', scopedSlots: { customRender: 'actions' }, width: 100 }
  ]

  openInvoicesColumns:Column[] = [
    { title: 'Omschrijving', key: 'description', dataIndex: 'description' },
    { title: 'Percentage', key: 'openToBillPercentage', scopedSlots: { customRender: 'openToBillPercentage' } },
    { title: 'Totaal', key: 'openToBillTotal', scopedSlots: { customRender: 'openToBillTotal' } }
  ]

  invoiceLineColumns:Column[] = [
    { title: 'Omschrijving', key: 'description', scopedSlots: { customRender: 'description' } },
    { title: 'Aantal', key: 'qty', dataIndex: 'qty' },
    { title: 'Eenheid', key: 'unit', dataIndex: 'unit' },
    { title: 'Totaal incl. BTW', key: 'price_with_vat', scopedSlots: { customRender: 'price_with_vat' } }
  ]

  openPdf (url:string) : void {
    window.open(url, '_blank')
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}

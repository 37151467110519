import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import BuildingModel from '@/models/BuildingModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
import SecureLS from 'secure-ls'
import axios from 'axios'
moment.locale('nl')

Vue.use(Vuex)
const storeName = 'buildings'
const storage = new SecureLS({ isCompression: false })
const defaultBuilding = {
  reference: null,
  number: null,
  state: 0,
  buildingblock_id: null,
  status_modular: 'test',
  status_carcass: 'test',
  status_completion: 'test',
  project_id: null,
  project: {
    name: null,
    reference: null
  },
  customer: {
    users: [],
    contractors: []
  },
  tenant: null,
  rooms: [],
  news: [],
  five_percent_rule: {
    active: false
  },
  received_orders: {
    modular: {
      received: false,
      order_id: null
    },
    carcass: {
      received: false,
      order_id: null
    },
    completion: {
      received: false,
      order_id: null
    },
    extra: {
      received: false,
      order_ids: null
    }
  },
  invoices: {
    sent: [],
    open: []
  },
  media: {
    buildingImage: [],
    verkoopdocumentatie: [],
    gebruikershandleiding: [],
    revisiedocumenten: [],
    kwaliteitdossier: [],
    overig: []
  }
}

export const buildingStore: Module<any, any> = {
  state: {
    buildingList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentBuilding: defaultBuilding,
    InactiveBuildingColumns: storage.get(storeName + '-columns') || [],
    loading: {
      list: false,
      item: false,
      save: false,
      syncing: false
    },
    errors: {}
  },
  mutations: {
    setCurrentBuilding (state, { currentBuilding }) {
      currentBuilding.end_modular = currentBuilding.end_modular ? moment(currentBuilding.end_modular) : null
      currentBuilding.end_carcass = currentBuilding.end_carcass ? moment(currentBuilding.end_carcass) : null
      currentBuilding.end_completion = currentBuilding.end_completion ? moment(currentBuilding.end_completion) : null
      currentBuilding.sale_date = currentBuilding.sale_date ? moment(currentBuilding.sale_date) : null
      currentBuilding.for_sale_date = currentBuilding.for_sale_date ? moment(currentBuilding.for_sale_date) : null
      currentBuilding.delivery_date = currentBuilding.delivery_date ? moment(currentBuilding.delivery_date) : null

      if (currentBuilding.delivery) {
        currentBuilding.delivery.inspection_scheduled_at = currentBuilding.delivery.inspection_scheduled_at ? moment(currentBuilding.delivery.inspection_scheduled_at) : null
        currentBuilding.delivery.delivery_scheduled_at = currentBuilding.delivery.delivery_scheduled_at ? moment(currentBuilding.delivery.delivery_scheduled_at) : null
      }

      state.currentBuilding = currentBuilding
    },
    clearCurrentBuilding (state) {
      state.currentBuilding = defaultBuilding
    },
    setBuildingList (state, { buildingList }) {
      state.buildingList = buildingList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    enabledBuildingOptionsChanged (state, { enabledOptions }) {
      state.currentBuilding.enabled_options = enabledOptions
    },
    fivePercentRuleChanged (state, { val }) {
      state.currentBuilding.five_percent_rule.active = val
    },
    demoAvailableChanged (state, { val }) {
      state.currentBuilding.demo_available = val
    },
    externalChanged (state, { val }) {
      state.currentBuilding.external = val
    },
    unityEnabledChanged (state, { val }) {
      state.currentBuilding.unity_enabled = val
    },
    customerChanged (state, { val }) {
      state.currentBuilding.customer_id = val.id
      state.currentBuilding.customer = val
    },
    tenantChanged (state, { val }) {
      state.currentBuilding.tenant_id = val.id
      state.currentBuilding.tenant = val
    },
    resetCustomer (state) {
      state.currentBuilding.customer_id = null
      state.currentBuilding.customer = null
    },
    resetTenant (state) {
      state.currentBuilding.tenant_id = null
      state.currentBuilding.tenant = null
    },
    setInactiveBuildingColumns (state, columns) {
      state.InactiveBuildingColumns = columns
    }
  },
  actions: {
    getBuildingList ({ commit }, settings) {
      commit('setLoading', { type: 'list', state: true })
      let buildingModel = new BuildingModel()
      buildingModel = buildingModel.where('delivered', settings.archived)

      // Add filters if there are any filters set.
      if (settings.activeFilters) {
        // Then check if they need to be filtered.
        Object.keys(settings.activeFilters).forEach(key => {
          if (settings.activeFilters[key].length) {
            buildingModel = buildingModel.whereIn(key, settings.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (settings.search) {
        buildingModel = buildingModel.where('search', settings.search)
      }

      // Add orderBy if sort is set.
      if (settings.sort) {
        buildingModel = buildingModel.orderBy(settings.sort.order === 'ascend' ? settings.sort.columnKey : '-' + settings.sort.columnKey)
      }

      if (settings.pagination) {
        buildingModel = buildingModel.limit(settings.pagination.pageSize).page(settings.pagination.current)
      }

      buildingModel.get().then((buildingList) => {
        commit('setBuildingList', { buildingList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van bouwnummers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentBuilding ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        BuildingModel.$find(id).then((currentBuilding) => {
          commit('setCurrentBuilding', { currentBuilding })
          resolve(currentBuilding)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit bouwnummer!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentBuilding ({ commit }) {
      commit('clearCurrentBuilding')
    },
    saveBuilding ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { media, news, ...buildingData } = data
      const buildingModel = new BuildingModel(buildingData)

      // Save building.
      buildingModel.save().then((currentBuilding) => {
        commit('setErrors', {})
        commit('setCurrentBuilding', { currentBuilding })
        if (data.id) {
          notification.success({ message: 'Bouwnummer opgeslagen.', description: 'Het bouwnummer is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Bouwnummer toegevoegd.', description: 'Het bouwnummer is succesvol toegevoegd.', duration: 3 })
          if (currentBuilding.id) {
            router.push({ name: 'buildingEdit', params: { buildingId: currentBuilding.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit bouwnummer!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit bouwnummer!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    enabledBuildingOptionsChanged ({ commit }, enabledOptions) {
      commit('enabledBuildingOptionsChanged', { enabledOptions })
    },
    fivePercentRuleChanged ({ commit }, val) {
      commit('fivePercentRuleChanged', { val })
    },
    demoAvailableChanged ({ commit }, val) {
      commit('demoAvailableChanged', { val })
    },
    externalChanged ({ commit }, val) {
      commit('externalChanged', { val })
    },
    unityEnabledChanged ({ commit }, val) {
      commit('unityEnabledChanged', { val })
    },
    customerChanged ({ commit }, val) {
      commit('customerChanged', { val })
    },
    tenantChanged ({ commit }, val) {
      commit('tenantChanged', { val })
    },
    resetCustomer ({ commit }) {
      commit('resetCustomer')
    },
    resetTenant ({ commit }) {
      commit('resetTenant')
    },
    setInactiveBuildingColumns ({ commit }, columns) {
      storage.set(storeName + '-columns', columns)
      commit('setInactiveBuildingColumns', columns)
    },
    syncBuilding ({ commit }, buildingId) {
      commit('setLoading', { type: 'syncing', state: true })
      axios.get(process.env.VUE_APP_API_URL + '/buildings/' + buildingId + '/sync').then((r) => {
        const currentBuilding = r.data.data
        commit('setCurrentBuilding', { currentBuilding })
        notification.success({
          message: 'Bouwnummer gesynchroniseerd.',
          description: 'Het bouwnummer is succesvol gesynchroniseerd met AFAS.',
          duration: 3
        })
      }).catch(() => {
        notification.error({
          message: 'Fout tijdens het synchroniseren!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
          duration: 0
        })
      }).finally(() => {
        commit('setLoading', { type: 'syncing', state: false })
      })
    }
  },
  getters: {
    buildingList: state => state.buildingList,
    currentBuilding: state => state.currentBuilding,
    loadingBuilding: state => state.loading,
    buildingErrors: state => state.errors,
    InactiveBuildingColumns: state => state.InactiveBuildingColumns
  }
}







































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Buildingblock from '@/types/buildingblock'
import axios from 'axios'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist, Uploader }
})

export default class BuildingShowDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding', errors: 'projectErrors' }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get productType () : string {
    switch (this.currentItem.product_type) {
      case 1:
        return 'Grondgebonden woning'
      case 2:
        return 'Appartement'
      case 3:
        return 'Garage'
      case 4:
        return 'VvE'
      default:
        return 'Overig'
    }
  }

  get objectType () : string {
    switch (this.currentItem.object_type) {
      case 1:
        return 'Koopwoning'
      case 2:
        return 'Huurwoning Tuvast'
      case 3:
        return 'Huurwoning Extern'
      default:
        return '-'
    }
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
